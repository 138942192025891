const formatMake = (make, separater) => {
  const uppercaseBrands = new Set(['BMW', 'GMC', 'MINI', 'RAM']);
  let formattedMake = make.toUpperCase();
  if (!uppercaseBrands.has(formattedMake)) {
    formattedMake = formattedMake.charAt(0) + formattedMake.slice(1).toLowerCase();
  }
  if (formattedMake.includes(separater)) {
    const split = formattedMake.split(separater);
    split[1] = split[1].charAt(0).toUpperCase() + split[1].slice(1);
    formattedMake = split.join(' ');
  }
  return formattedMake;
};

module.exports = formatMake;
